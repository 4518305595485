module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Get Fresh Books","short_name":"kahwa.club","start_url":"/","background_color":"#2C2B2B","theme_color":"#00e0ad","display":"minimal-ui","icon":"src/images/icon.png","icons":[{"src":"/icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/icons/icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/icons/mstile-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/icons/mstile-150x150.png","sizes":"150x150","type":"image/png"},{"src":"/icons/mstile-310x150.png","sizes":"310x150","type":"image/png"},{"src":"/icons/mstile-310x310.png","sizes":"310x310","type":"image/png"},{"src":"/icons/apple-touch-icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"/icons/apple-touch-icon-60x60.png","sizes":"60x60","type":"image/png"},{"src":"/icons/apple-touch-icon-72x72.png","sizes":"72x72","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1c9fd9d5450b22ab52f9e2486e1a9c93"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
